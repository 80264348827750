import React, { useState } from "react";
import classnames from "classnames";
import RuneTree, { SetterFn as RuneSetterFn, ResetStylesFn } from "./EditableRuneTree";
import StatShardTree, { SetterFn as StatShardSetterFn } from "./EditableStatShardTree";
import { RunePath } from "@outplayed/riot-assets";
import { OverviewInfo } from "@ugg/shared/api/data-parser/champions/overview";

function processSubStyle(runeData: RunePath[], subStyle: number, activePerks: number[]) {
  const subStyleData = runeData.filter((val) => val.id === subStyle)[0];
  const subStyleRunes: number[] = [];
  subStyleData.slots.forEach((val) => {
    const runeIds = val["runes"].map((data) => {
      return data.id;
    });
    subStyleRunes.push(...runeIds);
  });
  const subStylePerks = activePerks.filter((val: number) => {
    return subStyleRunes.includes(val);
  });
  return subStylePerks;
}

export interface RuneStaticData {
  rec_runes: Pick<NonNullable<OverviewInfo["rec_runes"]>, "primary_style" | "sub_style" | "active_perks">;
  stat_shards?: Pick<NonNullable<OverviewInfo["stat_shards"]>, "active_shards">;
}

interface EditableFullRuneTreeProps {
  className?: string;
  patch?: string;
  ssr?: boolean;
  skip?: boolean;
  isMobile?: boolean;
  recRunes: RuneStaticData["rec_runes"];
  statShards: RuneStaticData["stat_shards"];
  setStaticData?: (data: RuneStaticData) => void;
  editable?: boolean;
}

export default function EditableFullRuneTree(props: EditableFullRuneTreeProps) {
  const { className, patch, ssr, skip, isMobile, recRunes, statShards, setStaticData, editable } = props;

  const { primary_style: primaryStyle, sub_style: subStyle, active_perks: activePerks } = recRunes;
  const { active_shards } = statShards || {};
  const [showSelector, setShowSelector] = useState<number>(0);

  const setNewStyle: RuneSetterFn = (isActive, newRune, oldRune) => {
    if (isActive) {
      return;
    }
    const newRuneArray = activePerks.filter((val: number) => {
      return val !== oldRune;
    });
    newRuneArray.push(newRune);
    setStaticData &&
      setStaticData({
        ...(statShards && { stat_shards: statShards }),
        rec_runes: {
          ...recRunes,
          active_perks: [...newRuneArray],
        },
      });
  };

  const resetPrimaryStyle: ResetStylesFn = (primaryStyle, runeData) => {
    if (subStyle === primaryStyle) {
      setStaticData &&
        setStaticData({
          ...(statShards && { stat_shards: statShards }),
          rec_runes: {
            ...recRunes,
            primary_style: primaryStyle,
            sub_style: [8000, 8100, 8200, 8300, 8400].filter((val) => val !== primaryStyle)[0],
            active_perks: [],
          },
        });
    } else {
      if (recRunes.primary_style === primaryStyle) {
        return;
      }
      const subStylePerks = processSubStyle(runeData, subStyle, activePerks);
      setStaticData &&
        setStaticData({
          ...(statShards && { stat_shards: statShards }),
          rec_runes: {
            ...recRunes,
            primary_style: primaryStyle,
            sub_style: subStyle,
            active_perks: subStylePerks,
          },
        });
    }
  };

  const resetSubStyle: ResetStylesFn = (subStyle, runeData) => {
    if (recRunes.sub_style === subStyle) {
      return;
    }
    const subStylePerks = processSubStyle(runeData, primaryStyle, activePerks);
    setStaticData &&
      setStaticData({
        ...(statShards && { stat_shards: statShards }),
        rec_runes: {
          ...recRunes,
          primary_style: primaryStyle,
          sub_style: subStyle,
          active_perks: subStylePerks,
        },
      });
  };

  const setNewShard: StatShardSetterFn = (isActive, newShard, row) => {
    if (isActive || !active_shards) {
      return;
    }
    const newShardArray = [...active_shards];
    newShardArray[row] = newShard;
    setStaticData &&
      setStaticData({
        stat_shards: {
          ...statShards,
          active_shards: newShardArray,
        },
        rec_runes: {
          ...recRunes,
        },
      });
  };

  return (
    <div className={classnames("rune-trees-container", className)}>
      <RuneTree
        patch={patch}
        perkStyle={primaryStyle}
        activePerks={activePerks}
        primaryPerk
        ssr={ssr}
        skip={skip}
        isMobile={isMobile}
        {...(editable && {
          setterFunction: setNewStyle,
          resetStyles: resetPrimaryStyle,
          showSelector,
          setShowSelector,
        })}
      />
      <div className="secondary-tree">
        <RuneTree
          patch={patch}
          perkStyle={subStyle}
          primaryStyleId={primaryStyle}
          activePerks={activePerks}
          isSubStyle
          ssr={ssr}
          skip={skip}
          isMobile={isMobile}
          {...(editable && {
            setterFunction: setNewStyle,
            resetStyles: resetSubStyle,
            showSelector,
            setShowSelector,
          })}
        />
        {active_shards && (
          <React.Fragment>
            <div className={classnames("stat-shard-divider", { "stat-shard-divider_mobile": isMobile })} />
            <StatShardTree
              patch={patch}
              activeShards={active_shards}
              ssr={ssr}
              isMobile={isMobile}
              {...(editable && { setterFunction: setNewShard })}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
